.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

/* @import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";
@import "https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,500,600,700"; */

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.2s;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar {
  min-width: 250px;
  max-width: 250px;
  background: #424C5C;
  color: #adb5bd;
  margin-left: -250px;
  transition: all 0.5s;
  text-align: center;
}

.sidebar.is-open {
  margin-left: 0;
  transition: .5s;
}


.sidebar-header {
  background: #424C5C;
  color: #adb5bd;
  text-align: left;
}

.sidebar-header h3{
  color: #fff;
  padding: 1em;
}

.sidebar ul p {
  color: #fff;
  padding: 10px;
}

.collapse.show, 
.collapsing {
  background: #424C5C;
}

.nav-item a {
  font-weight: 500;
  color: #46B249;
}

.nav-item a.open {
  color: #e9ecef;
  background: #424C5C;
}

.nav-item.active a {
  color: #fff;
  background: #46B249 !important;
}

.nav-item a:hover {
  background: #46B249;
  color: #fff;
  transition: .2s;
}

.nav-item a .svg-inline--fa {
  color: inherit;
}

.logout-item a:hover {
  background: inherit;
  color: #fff;
  transition: .2s;
}

li a.dropdown-toggle::after {
  display: inline-flex;
  position: relative;
  left: 60%;
  top: 10%
}

.sidebar-header > button {
  position: relative;
  float: right;
  margin: .5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}

/* ---------------------------------------------------
    NAVBAR STYLE
----------------------------------------------------- */
.admin-nav-btn {
  color: #46B249 !important;
  border-color: #46B249 !important;
}

.admin-nav-btn:hover {
  color: #fff !important;
  background-color: #46B249 !important;
}

.admin-nav-btn:focus {
  box-shadow: none !important;
}

.pd-l-20 {
  padding-left: 20px;
}

.color-primary {
  color: #46B249;
  font-weight: 500;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.content {
  padding: 20px;
  margin-left: 0;
  height: 100vh;
  overflow: auto;
}

@media only screen and (max-width: 575.98px) {
  body {
    overflow:  hidden;
  }
  
  .content.is-open {
    /*margin-left: 100%;*/
    display: none;
    transition: all 0.5s;
  }
  
  .sidebar.is-open {
    min-width: 100%;
    max-width: 100%;
    margin-left: 0;
    transition: all 0.5s, height 0s;
    height: 100vh !important;
  }
  
  .sidebar.is-open > .sidebar-header button {
    display: block;
  }
  
  li a.dropdown-toggle::after {
    display: inline-block;
    position: relative;
    left: 68%;
  }
}