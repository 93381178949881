
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;500;700&display=swap');

html {
    font-family: 'Manrope', sans-serif !important;
    font-weight: 300;
}

h1, h2, h3, h4, p, label, select, input, li, table, button {
  font-family: 'Manrope', sans-serif !important;
}

h1, h2, h3 {
  font-weight: 700 !important;
}

.page {
  padding-bottom: 150px;
}

.pd-t {
  padding-top: 20px;
}

.alert {
  font-family: 'Manrope', sans-serif !important;
  font-weight: 300;
}

/* NAVBAR STYLING */
.navbar {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #fff;
}

.navbar .container {
  padding-bottom: 5px;
}

.nav-link a {
  color: #050302;
  font-weight: bold;
  text-decoration: none;
}

.nav-link .active {
  color: #46B249;
}

/* HOME PAGE STYLING */
.main {
  text-align: center;
}

.container-left {
  text-align: left;
}

.jumbotron {
  background-color: #46B249;
  /* text-align: center; */
  padding-top: 25px;
  padding-bottom: 25px;
  margin-bottom: 40px;
  color: #fff;
  font-weight: 400;
}

.jumbotron h4 {
  font-weight: 300 !important;
}

.jb-col {
  padding-top: 10px;
  padding-bottom: 10px;
}