#admin-h1, #admin-h2, #admin-h3 {
  color: #46B249;
}

.admin-tablerow:hover {
  cursor: pointer;
  background-color: rgba(92, 163, 78, 0.4);
}

#admin-btn {
  border-color: #46B249;
  background-color: #fff;
  color: #46B249;
  border-radius: 25px;
  transition: all .2s;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  transition-delay: 0s;
  border-width: 1px;
  padding: 9px 20px;
  margin: 9px;
  font-family: 'Manrope', sans-serif;
  font-weight: 500;
  font-size: 0.9rem;
}

#admin-btn:hover {
  background-color: #46B249;
  color: #fff;
  transition: all .2s;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  transition-delay: 0s;
}

#admin-link {
  margin-top: 10px;
  color: #46B249;
  transition: all .2s;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  transition-delay: 0s;
  text-decoration: underline #46B249;
}

#admin-form-label {
  font-weight: 300;
  color: #424C5C;
  font-size: 18px;
  padding: 5px;
}

#btn-link-style {
  color: #46B249;
  font-weight: bold;
  background-color: transparent;
  border: none;
  transition: all .2s;
  padding: 4px;
}

#btn-link-style:hover {
  color: #424C5C;
  transition: all .2s;
  box-shadow: none !important;
}

#btn-generic-style {
  color: #FFF;
  background-color: #46B249;
  transition: all .2s;
  border-color: #46B249;
}

#btn-generic-style:hover {
  color: #46B249;
  background-color: #FFF;
  transition: all .2s;
  box-shadow: none !important;
}

.admin-link {
  color: #424C5C;
  transition: all .2s;
}

.admin-link:hover {
  color: #46B249;
  transition: all .2s;
  cursor: pointer;
}

/* ---------------------------------------------------
    ANALYTICS STYLE
----------------------------------------------------- */

.stat-col {
  padding: 20px;
}

.stat-card {
  width: 100%;
}

.stat-num {
  font-size: 4em;
  font-weight: 500;
  display: block;
}

.stat-caption {
  color: #424C5C;
  font-weight: bold;
}

.stat-card .card-body {
  padding-bottom: 30px;
}

/* ---------------------------------------------------
    LEADS STYLE
----------------------------------------------------- */

.sort-select {
  width: fit-content;
}

/* ---------------------------------------------------
    COMPANIES STYLE
----------------------------------------------------- */

.admin-modal-tablerow {
  border-style: hidden;
}