.color-req {
    color: #790000;
}

#text-center {
    text-align: center;
}

#form-card {
    border: none;
}

#form-step {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d1f1cb;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

#form-step:active {
    border-color: #46B249;
}

#form-step:focus {
    border-color: #46B249;
    outline-style: none;
}

#form-btn {
    border-color: #46B249;
    background-color: #fff;
    color: #46B249;
    border-radius: 25px;
    transition: all .2s;
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    transition-delay: 0s;
    border-width: 1px;
    padding: 9px 20px;
    margin: 9px;
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 0.9rem;
}

#form-btn:hover {
    background-color: #46B249;
    color: #fff;
    transition: all .2s;
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    transition-delay: 0s;
}

.form-label {
    font-size: 1.3rem;
    font-weight: bold;
}

.step {
    width: 3em;
    height: 3em;
    /* background: #03a9f4; */
  }
  
.step.progress {
    background: #46B249;
}

.progress-bar.progress {
    background: #d1f1cb;
}

.progress-bar.progress::after {
    background: #46B249;
}

.progress .progress-bar {
    background-color: #46B249 !important;
}

#text-small {
    font-size: 12px;
}

.text-left {
    text-align: left;
}

#group-center > div > div {
    display: flex;
    place-content: center;
}

.form-check-input:checked {
    background-color: #46B249 !important;
    border-color: #46B249 !important;
}

.form-check-input:focus {
    box-shadow: none !important;
}